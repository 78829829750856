body {
  opacity: 0.95;
  background: url("../../../assets/activity/novice/background.png");
  background-size: cover;
}
.ranking {
  height: 100vh;
  position: relative;
  :global {
    .back_card {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 99;
      padding: 0 20px;
      img {
        width: 44px;
        height: 44px;
      }
      .back_text {
        display: inline-block;
        font-size: 16px;
        font-family: LozangeNoCommercial, LozangeNoCommercial-400;
        font-weight: 400;
        color: #c9fff2;
        line-height: 44px;
        vertical-align: top;
        margin-left: 4px;
        img {
          width: 16px;
          height: 16px;
          margin-left: 2px;
          position: relative;
          top: 3px;
        }
      }
    }

    .record_card {
      height: 100%;
      display: flex;
      .record_card_left {
        flex: 18;
        height: 100%;
        padding: 0 28px;
        padding-top: 40px;
        .text {
          font-size: 12px;
          font-family: OPPOSans, OPPOSans-400;
          font-weight: 800;
          text-align: LEFT;
          color: #c9fff3;
        }
        .record_table {
          //background: white;
          width: 100%;
          margin-top: 10px;
          .table_header {
            width: 100%;
            height: 48px;
            background: rgba(0,19,15,0.16);
            padding: 0 20px;
            box-sizing: border-box;

            font-size: 10px;
            font-family: OPPOSans, OPPOSans-400;
            font-weight: 800;
            text-align: LEFT;
            color: #c9fff3;
            display: flex;
            justify-content: space-between;
            >div {
              height: 100%;
              position: relative;
              div {
                width: 100%;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
              }
            }
          }
          .table_body {
            width: 100%;
            height: calc(100vh - 120px);
            overflow: auto;
            .table_body_item {
              width: 100%;
              height: 48px;
              //background: rgba(0,19,15,0.16);
              padding: 0 20px;
              box-sizing: border-box;
              border-bottom: 1px solid rgba(255,255,255,0.2);

              font-size: 12px;
              font-family: OPPOSans, OPPOSans-400;
              font-weight: 500;
              text-align: LEFT;
              color: #ffffff;
              display: flex;
              justify-content: space-between;
              >div {
                height: 100%;
                position: relative;
                div {
                  position: absolute;
                  top: 50%;
                  transform: translateY(-50%);
                  width: 100%;
                  img {
                    width: 16px;
                    height: 16px;
                    margin-right: 2px;
                    &:last-child {
                      margin-right: 0;
                    }
                  }
                }
              }
            }
          }
        }

      }
      .record_card_right {
        flex: 12;
        height: 100%;
        //background: rgba(0,19,15,0.4);
        padding-left: 40px;
        padding-top: 12px;
        position: relative;
        .right_body {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }
        .img_card {
          position: relative;
          .back_light {
            position: absolute;
            top: 50%;
            left: 50%;
            transform-origin: center center;
            transition: all linear 1s;
            z-index: -1;
            width: 400px;
          }
          img {
            width: 280px;
            //height: 280px;
          }
          &::before{
            content: " ";
            display: block;
            position: absolute;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background-color: #ff3b30;
            right: 85px;
            top: 85px;
          }
        }
        .record_tip {
          margin-top: -24px;
          //bottom: 0;
          .title_h1 {
            font-size: 16px;
            font-family: OPPOSans, OPPOSans-400;
            font-weight: 800;
            text-align: CENTER;
            color: #c9fff3;
          }
          .title_time_card {
            margin-top: 16px;
            text-align: center;
            .label {
              font-size: 12px;
              font-family: OPPOSans, OPPOSans-400;
              font-weight: 800;
              text-align: LEFT;
              color: #c9fff3;
              display: inline-block;
            }
            .time_card {
              margin-left: 8px;
              display: inline-block;
              .time_label {
                //width: 100px;
                display: flex;
                justify-content: space-between;
                font-size: 10px;
                font-family: OPPOSans, OPPOSans-400;
                font-weight: 800;
                text-align: CENTER;
                color: #ffffff;
                >div {
                  padding: 3px;
                  border: 1px solid #00ffc6;
                  &:nth-child(2),&:nth-child(4) {
                    border: none;
                  }
                }
              }
            }
          }
        }

      }
    }

  }
}
