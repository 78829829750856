@font-face {
  font-family: "iconfont"; /* Project id  */
  src: url('iconfont.ttf?t=1697610237949') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-jichuicon-:before {
  content: "\e614";
}

