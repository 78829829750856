.activityMarkCard {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: rgba(0,0,0,0.8);
  :global {
    .a_link {
      color: #5AC8FA;
    }
    .activity_card_body {
      width: 440px;
      height: 287px;
      padding: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      border: 1px solid rgba(0,255,198,0.50);
      box-sizing: border-box;
      overflow: hidden;
      .card_body_header {
        padding: 0 20px;
        width: 100%;
        height: 46px;

        background: rgba(6,110,82,1);
        font-size: 16px;
        font-family: LozangeNoCommercial, LozangeNoCommercial-400;
        font-weight: 400;
        color: #c9fff3;
        line-height: 46px;
        box-sizing: border-box;
        overflow: hidden;
        img {
          width: 28px;
          height: 28px;
          float: right;
          margin-top: 8px;
        }
      }
      .card_body_content {
        width: 100%;
        height: calc(100% - 46px);
        background: rgba(6,79,62,1);
        overflow: auto;
        box-sizing: border-box;
        padding: 20px;
        font-size: 12px;
        font-family: OPPOSans, OPPOSans-400;
        font-weight: 500;
        text-align: LEFT;
        color: #ffffff;
        p {
          margin-bottom: 20px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
    .phone_number {
      font-size: 12px;
      font-family: OPPOSans, OPPOSans-400;
      font-weight: 800;
      color: #00FFC6;
    }
    .copy_image {
      width: 11px;
    }
  }
}
