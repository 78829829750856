body {
  opacity: 0.95;
  background: url("../../../assets/activity/novice/background.png");
  background-size: cover;
}
.rewards {
  height: 100vh;
  position: relative;
  :global {
    .back_card {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 99;
      padding: 0 20px;
      img {
        width: 44px;
        height: 44px;
      }
      .back_text {
        display: inline-block;
        font-size: 16px;
        font-family: LozangeNoCommercial, LozangeNoCommercial-400;
        font-weight: 400;
        color: #c9fff2;
        line-height: 44px;
        vertical-align: top;
        margin-left: 4px;
        img {
          width: 16px;
          height: 16px;
          margin-left: 12px;
          position: relative;
          top: 3px;
        }
      }
    }
    .novice_body {
      display: flex;
      width: 100%;
      height: 100%;
      .novice_left {
        flex: 15;
        height: 100%;
        position: relative;
        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
        }
      }
      .novice_right {
        flex: 20;
        height: 100%;
        padding-right: 47px;
        .novice_right_title {
          margin-top: 9vh;
          margin-left: 44px;
          .title_1 {
            font-size: 16px;
            font-family: OPPOSans, OPPOSans-400;
            font-weight: 800;
            text-align: LEFT;
            color: #c9fff3;
          }
          .title_2 {
            font-size: 16px;
            font-family: OPPOSans, OPPOSans-400;
            font-weight: 800;
            text-align: left;
            color: #c9fff3;
          }
        }

        .novice_list_card {
          margin-left: 44px;
          margin-top: 30px;
          position: relative;
          //background: #ff3b30;
          .novice_list_card_item {
            margin-top: 5vh;
            padding-bottom: 5vh;
            border-bottom: 1px solid rgba(255,255,255,0.2);
            &:first-child {
              padding-top: 0;
            }
            &:nth-child(3) {
              border-bottom: none;
            }
            display: flex;
            justify-content: space-between;
            .content_text {
              display: inline-block;
              font-size: 10px;
              font-family: OPPOSans, OPPOSans-400;
              font-weight: 500;
              text-align: LEFT;
              line-height: 24px;
              color: #ffffff;
              padding-right: 20px;
            }
            .text_line {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              word-break: keep-all;
            }
            .novice_button {
              display: inline-block;
              //float: right;
              img {
                position: relative;
                top: 50%;
                margin-top: -12px;
              }
            }
          }

          .button_card {
            font-size: 14px;
            font-family: LozangeNoCommercial, LozangeNoCommercial-400;
            font-weight: 400;
            text-align: CENTER;
            color: #ffffff;
            line-height: 40px;
            background: linear-gradient(180deg,#064f3e, #00e2b0 100%);
            border: 1px solid;
            border-image: linear-gradient(180deg, #d7fff6, #00ffc6 100%) 1 1;
            box-shadow: 0px 0px 8px 0px #00ffc6 inset, 0px 0px 8px 0px #00ffc6 inset;
            display: inline-block;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            width: 120px;
            text-align: center;
          }

        }
        .button_get {
          margin-top: 24px;
          width: 100%;
          text-align: center;
          .botton {
            display: inline-block;
            line-height: 40px;
            font-size: 14px;
            font-family: LozangeNoCommercial, LozangeNoCommercial-400;
            font-weight: 400;
            text-align: CENTER;
            color: #ffffff;
            text-shadow: 0px 0px 4px 0px #00ffc6;
            padding: 0 47px;
            background: linear-gradient(180deg,#064f3e, #00e2b0 100%);
            border: 1px solid;
            border-image: linear-gradient(180deg, #d7fff6, #00ffc6 100%) 1 1;
            box-shadow: 0px 0px 8px 0px #00ffc6 inset, 0px 0px 8px 0px #00ffc6 inset;
          }
        }
      }
    }
    .mytitle {
      display: inline-block;
      img {
        //float: none !important;
        margin-left: 4px;
        width: 16px;
        height: 16px;
      }
    }
    .myButton {
      margin-top: 50px;
      text-align: center;
      .button {
        display: inline-block;
        line-height: 40px;
        font-size: 14px;
        font-family: LozangeNoCommercial, LozangeNoCommercial-400;
        font-weight: 400;
        text-align: CENTER;
        color: #ffffff;
        text-shadow: 0px 0px 4px 0px #00ffc6;
        padding: 0 21px;
        background: linear-gradient(180deg,#064f3e, #00e2b0 100%);
        border: 1px solid;
        border-image: linear-gradient(180deg, #d7fff6, #00ffc6 100%) 1 1;
        box-shadow: 0px 0px 8px 0px #00ffc6 inset, 0px 0px 8px 0px #00ffc6 inset;
      }
    }
  }
}
