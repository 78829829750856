body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-size: cover;
  background-repeat: no-repeat;
  color: #FFFF;
}

/*.background_1 {*/
/*  content: "";*/
/*  display: block;*/
/*  position: absolute;*/
/*  top: 0;*/
/*  left: 0;*/
/*  height: 100%;*/
/*  width: 100%;*/
/*  opacity: 0.9;*/
/*  background-color: #000000;*/
/*}*/
/*.background_2{*/
/*  content: "";*/
/*  display: block;*/
/*  position: absolute;*/
/*  top: 0;*/
/*  left: 0;*/
/*  height: 100%;*/
/*  width: 100%;*/
/*  opacity: 0.6;*/
/*  !*background: linear-gradient(180deg,rgba(0,10,7,1), rgba(0,34,26,1) 100%);*!*/
/*  background: linear-gradient(0deg,rgba(0,255,198,0.20) 0%, rgba(0,255,198,0.00));*/
/*}*/

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "LozangeNoCommercial";
  src: url("./assets/font/Lozange Display Font.otf");
}

@font-face {
  font-family: "OPPOSans-Medium";
  src: url("./assets/font/Font-OPPOSans/OPPOSans-M.ttf");
}

@font-face {
  font-family: "OPPOSans-Heavy";
  src: url("./assets/font/Font-OPPOSans/OPPOSans-H.ttf");
}

@font-face {
  font-family: "Paytone-One";
  src: url("./assets/font/PAYTONEONE-REGULAR.ttf");
}

@keyframes sk-foldCubeAngle {
  0%,
  10% {
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%,
  75% {
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%,
  100% {
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}
/*  begin folding css */
.folding {
  margin: 20px auto;
  width: 40px;
  height: 40px;
  position: relative;
  transform: rotateZ(45deg);
}

.folding .sk-cube {
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  transform: scale(1.1);
}

.folding .sk-cube:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #c9fff2;
  animation: sk-foldCubeAngle 2.4s infinite linear both;
  transform-origin: 100% 100%;
}

.folding .sk-cube2 {
  transform: scale(1.1) rotateZ(90deg);
}

.folding .sk-cube3 {
  transform: scale(1.1) rotateZ(180deg);
}

.folding .sk-cube4 {
  transform: scale(1.1) rotateZ(270deg);
}

.folding .sk-cube2:before {
  animation-delay: 0.3s;
}

.folding .sk-cube3:before {
  animation-delay: 0.6s;
}

.folding .sk-cube4:before {
  animation-delay: 0.9s;
}
@keyframes spin {
  100% { transform: rotate(360deg); }
}

@import "./assets/font/font_icon/iconfont.css";
