.gameUserAccountForm {
  width: 100%;
  //padding: 24px 24px;
  box-sizing: border-box;
  //background: linear-gradient(180deg,#066e52, #064f3e 100%);
  //border: 1px solid rgba(0,255,198,0.50);
  position: relative;
  //margin-bottom: 24px;
  :global {
    .a_link {
      color: #5AC8FA;
    }
    .left_card {
      //height: calc(36vh);
      //background-color: white;
      overflow: auto;
      .phone_number {
        color: #00FFC6;
      }
      img {
        width: 12px;
        height: 12px;
        vertical-align: top;
        margin-left: 2px;
      }
    }
    .input_card {
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
      .label {
        line-height: 24px;
        //background-color: white;
        width: 70px;
        display: inline-block;
      }
      .input {
        display: inline-block;
        vertical-align: top;
        width: 100%;
        input {
          width: 100%;
          padding: 0 12px;
          box-sizing: border-box;
          background-color: #074537;
          outline: none;
          border: 1px solid #007a5f;
          font-size: 12px;
          font-family: OPPOSans, OPPOSans-400;
          color: white;
          line-height: 40px;
          font-weight: 800;
        }
      }
    }
    .right_card {
      padding: 12px 32px;
      background: linear-gradient(180deg,#064f3e, #00e2b0 100%);
      position: relative;
      margin-top: 12px;
      //right: 12px;
      left: 50%;
      transform: translateX(-50%);
      display: inline-block;
      font-size: 12px;
      font-family: LozangeNoCommercial, LozangeNoCommercial-400;
      border: 1px solid;
      border-image: linear-gradient(180deg, #d7fff6, #00ffc6 100%) 1 1;
      box-shadow: 0px 0px 4px 0px #00ffc6 inset, 0px 0px 4px 0px #00ffc6 inset;
    }
    .loading_card {
      position: fixed;
      background-color: rgba(0,0,0,0.6);
      z-index: 999;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      img {
        width: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
      }
    }
  }
}
