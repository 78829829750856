.prizes {
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,0.85);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  padding: 0 26px;
  box-sizing: border-box;
  :global {
    .close_icon {
      float: right;
      margin-top: 20px;
      margin-right: 40px;
    }
    .prizes_title {
      font-size: 24px;
      font-family: OPPOSans, OPPOSans-400;
      font-weight: 800;
      text-align: CENTER;
      color: #ffffff;
      text-align: center;
      margin: 40px 0;
    }
    .prizes_card {
      display: flex;
      justify-content: space-around;
      //padding: 0 132px;
      flex-wrap: wrap;
      .prizes_card_item {
        img {
          width: 100px;
          height: 100px;
        }
        .prizes_text {
          width: 24vw;
          display: inline-block;
          position: relative;
          bottom: 50%;
          transform: translateY(50%);
          div {
            margin-left: 12px;
            font-size: 12px;
            font-family: OPPOSans, OPPOSans-400;
            font-weight: 400;
            color: rgba(255,255,255,0.8);
            text-align: left;
            &:first-child {
              font-size: 16px;
              font-family: OPPOSans, OPPOSans-400;
              font-weight: 800;
              text-align: left;
              color: #ffffff;
              margin-bottom: 6px;
            }

          }
        }
      }
    }
  }
}
