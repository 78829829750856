.main {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0.95;
  z-index: 99999;
  pointer-events: none;
  :global {
    //.card {
    //  text-align: center;
    //  .card_title_card {
    //    position: relative;
    //    .card_title {
    //      margin: 10vh auto;
    //      line-height: 40px;
    //      width: 220px;
    //      font-size: 20px;
    //      font-family: LozangeNoCommercial, LozangeNoCommercial-Regular;
    //      font-weight: 400;
    //      //text-shadow: 0px 0px 8px #ff9500;
    //      .card_title_back {
    //        position: absolute;
    //        top: 10px;
    //        width: 220px;
    //        height: 20px;
    //        opacity: 0.6;
    //        //background: radial-gradient(rgba(255,149,0,0.00), #ff9500, rgba(255,149,0,0.00));
    //        border-radius: 50%;
    //        filter: blur(12px);
    //        z-index: -1;
    //      }
    //    }
    //  }
    //  .close_icon {
    //    position: absolute;
    //    top: 8px;
    //    right: 20px;
    //    cursor: pointer;
    //  }
    //}
    //.message_card {
    //  width: 100%;
    //  height: 140px;
    //
    //  background-size: cover;
    //  background-repeat: no-repeat;
    //  line-height: 140px;
    //  font-size: 16px;
    //  font-family: OPPOSans, OPPOSans-Medium;
    //}
    //.message_card_warning {
    //  background-image: url("../../assets/icon/dialog_prompt_warning.png");
    //}
    //.message_card_full {
    //  background-image: url("../../assets/icon/promptlayer_background.png");
    //}
    //.button_card {
    //  display: inline-block;
    //  width: 173px;
    //  height: 68px;
    //  background-repeat: no-repeat;
    //  background-size: cover;
    //  margin-top: 22px;
    //  font-size: 16px;
    //  font-family: LozangeNoCommercial, LozangeNoCommercial-Regular;
    //  line-height: 68px;
    //}
    //.button_card_warning {
    //  background-image: url("../../assets/icon/button_warning.png");
    //  text-shadow: 0px 0px 4px #ff9500;
    //}
    //.button_card_full {
    //  background-image: url("../../assets/icon/button_success.png");
    //  text-shadow: 0px 0px 4px #00ffc6;
    //}
    .img_back {
      width: 480px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }
    .toast_text {
      font-size: 16px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      width: 100%;
      text-align: center;
    }
  }
}
